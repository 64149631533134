import HelperService from "@/services/HelperService";
import axios from "@/libs/axios";

const baseUrl = "/the-hub";

export default {
  createArticle(data) {
    return axios.post(`${baseUrl}/articles`, data);
  },
  listArticles(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/articles${queryString}`);
  },
  getArticlesByTagId(id, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/articles/tags/${id}`, queryString);
  },
  getArticleLikes(id, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/likes/article/${id}${queryString}`);
  },
  createTag(data) {
    return axios.post(`${baseUrl}/tags`, data);
  },
  uploadTagImage(id, file) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${baseUrl}/tags/${id}/image`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  listTags(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/tags${queryString}`);
  },
  getTag(id) {
    return axios.get(`${baseUrl}/tags/${id}`);
  },
  updateTag(id, data) {
    return axios.put(`${baseUrl}/tags/${id}`, data);
  },
  deleteTag(id) {
    return axios.delete(`${baseUrl}/tags/${id}`);
  },
  deleteTagImage(id) {
    return axios.delete(`${baseUrl}/tags/${id}/image`);
  },
  getArticle(id) {
    return axios.get(`${baseUrl}/articles/${id}`);
  },
  uploadArticleThumbnail(id, file) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${baseUrl}/articles/${id}/thumbnail`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteArticleThumbnail(id) {
    return axios.delete(`${baseUrl}/articles/${id}/thumbnail`);
  },
  uploadArticleVideo(id, file) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${baseUrl}/articles/${id}/video`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  deleteArticleVideo(id) {
    return axios.delete(`${baseUrl}/articles/${id}/video`);
  },
  uploadArticlePDF(id, file) {
    const formData = new FormData();
    formData.append("file", file);

    return axios.post(`${baseUrl}/articles/${id}/pdf`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  generateArticlePDFSummary(id) {
    return axios.get(`${baseUrl}/articles/${id}/pdf-summary`);
  },
  deleteArticlePDF(id) {
    return axios.delete(`${baseUrl}/articles/${id}/pdf`);
  },
  updateArticle(id, data) {
    return axios.put(`${baseUrl}/articles/${id}`, data);
  },
  deleteArticle(id) {
    return axios.delete(`${baseUrl}/articles/${id}`);
  },
  listArticleComments(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/comments${queryString}`);
  },
  getArticleComment(id) {
    return axios.get(`${baseUrl}/comments/${id}`);
  },
  updateArticleComment(id, data) {
    return axios.put(`${baseUrl}/comments/${id}`, data);
  },
  updateArticleCommentReply(id, data) {
    return axios.put(`${baseUrl}/comments/${id}/reply`, data);
  },
  deleteArticleCommentReply(id) {
    return axios.delete(`${baseUrl}/comments/${id}/reply`);
  },
};
