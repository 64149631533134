<template>
  <div class="mt-1 row text-center justify-content-center">
    <div class="col-10 position-relative">
      <transition mode="out-in" name="fade">
        <img
          :key="currentIndex"
          :src="states[currentIndex].image"
          alt="Processing Image"
          class="loading-image rounded pulse-animation"
        >
      </transition>
    </div>
    <div class="mt-2 row align-items-center justify-content-center">
      <b-spinner class="mr-1" label="Loading..." variant="primary" />

      <p class="mt-1">{{ states[currentIndex].message }}</p>
    </div>
    <div class="row mt-1">
      <div class="col-10 mx-auto">
        <slot name="message" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingCarousel",
  props: {
    states: {
      type: Array,
      required: true,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      currentIndex: 0,
      interval: null,
    };
  },
  mounted() {
    this.startCarousel();
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    startCarousel() {
      this.interval = setInterval(() => {
        if (this.currentIndex < this.$props.states.length - 1) {
          this.currentIndex =
            (this.currentIndex + 1) % this.$props.states.length;
        }
      }, this.$props.timeout);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-image {
  max-height: 200px;
  max-width: 200px;
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.pulse-animation {
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transition: opacity 1s;
}
</style>
