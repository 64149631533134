<template>
  <div>
    <div class="activity-page">
      <b-card class="" title="Articles">
        <b-btn
          v-if="$can('Create', 'TheHub.Articles')"
          class="float-right"
          style="margin-top: -10px"
          variant="primary"
          @click="$bvModal.show('modal-create-article-from-pdf')"
        >
          Create Article From PDF
        </b-btn>

        <b-btn
          v-if="$can('Create', 'TheHub.Articles')"
          class="float-right mr-1"
          style="margin-top: -10px"
          variant="primary"
          @click="$bvModal.show('modal-create-article')"
        >
          Create Article
        </b-btn>
        <b-card-text>
          This page shows all articles stored for TheHub.
        </b-card-text>
      </b-card>

      <b-card class="mb-0" no-body>
        <div class="row px-2 py-1 align-items-center justify-content-between">
          <section class="col-4">
            <label>Search</label>
            <b-input-group>
              <b-form-input
                v-model="filters.search"
                class="d-inline-block"
                placeholder="Search article title..."
                @keydown.enter="getMoreArticles(1)"
              />
              <b-input-group-append>
                <b-button @click="getMoreArticles(1)">Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </section>

          <section v-if="$can('Read', 'User')" class="col-2">
            <label>Author</label>
            <user-search-select :selected="selectedAuthor" @change="updateSelectedAuthor" />
          </section>

          <section class="col-2">
            <label>Status</label>
            <b-form-select
              v-model="filters.published"
              @change="getMoreArticles(1)"
            >
              <b-form-select-option value=""> All</b-form-select-option>
              <b-form-select-option value="true">
                Published
              </b-form-select-option>
              <b-form-select-option value="false">Draft</b-form-select-option>
            </b-form-select>
          </section>

          <section class="col-2">
            <label>Sort</label>
            <b-form-select v-model="selectedSort" @change="updateOrderFilter">
              <b-form-select-option value="newest">Newest</b-form-select-option>
              <b-form-select-option value="oldest">Oldest</b-form-select-option>
              <b-form-select-option value="likes">
                Most Likes
              </b-form-select-option>
              <b-form-select-option value="comments">
                Most Comments
              </b-form-select-option>
            </b-form-select>
          </section>

          <section class="col-2 d-flex justify-content-end align-items-center">
            <b-button @click="clearFilters()">Clear filters</b-button>
          </section>
        </div>
        <section>
          <b-table
            :current-page="currentPage"
            :fields="headers"
            :items="articles"
            :per-page="0"
            class="data-table"
            hover
          >
            <template #cell(date)="data">
              <!-- Supports old version of meta data-->
              <span>
                {{ data.item.date | formatDate }}
              </span>
            </template>

            <template #cell(is_published)="data">
              <span>{{ data.item.is_published ? "Published" : "Draft" }}</span>
            </template>

            <template #cell(actions)="data">
              <router-link
                :to="{ name: 'article-details', params: { id: data.item.id } }"
                class="font-weight-bold d-block text-nowrap"
              >
                View article
              </router-link>
            </template>
          </b-table>
          <b-row>
            <b-col v-if="total_count > filters.page_size" class="my-1" md="6">
              <b-pagination
                v-model="currentPage"
                :per-page="filters.page_size"
                :total-rows="total_count"
                class="my-0 mx-1"
                @change="getMoreArticles"
              />
            </b-col>
            <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
          </b-row>
        </section>
      </b-card>

      <b-modal
        id="modal-create-article"
        class="modal-create-article"
        hide-footer
        size="xl"
        title="Create Article"
      >
        <article-editor :show-delete="false" />
      </b-modal>

      <b-modal
        id="modal-create-article-from-pdf"
        class="modal-create-article"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        no-close-on-esc
        size="md"
        title="Create Article From PDF"
      >
        <article-from-pdf @close="closeModals" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import TheHubService from "@/services/TheHubService";
import ArticleEditor from "@/views/theHub/sections/ArticleEditor.vue";
import ArticleFromPdf from "@/views/theHub/sections/ArticleFromPdf.vue";
import UserSearchSelect from "@/components/input/PaginatedSearchSelect/UserSearchSelect.vue";

export default {
  name: "Articles",
  components: {
    UserSearchSelect,
    ArticleFromPdf,
    ArticleEditor,
  },
  data() {
    return {
      headers: [
        {
          key: "title",
          label: "Title",
          class: "col-4",
        },
        this.$can("Read", "User") && {
          key: "author.name",
          label: "Author",
        },
        {
          key: "date",
          label: "Date",
        },
        this.$can("Read", "TheHub.Likes") && {
          key: "like_count",
          label: "Likes",
        },
        this.$can("Read", "TheHub.Comments") && {
          key: "comment_count",
          label: "Comments",
        },
        {
          key: "is_published",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
          class: "text-right",
        },
      ],
      articles: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        search: "",
        author_uid: "",
        order_by: "desc",
        order: "date",
        published: "",
      },
      selectedSort: "newest",
      total_count: 0,
      loading: true,
      selectedAuthor: null,
    };
  },
  mounted() {
    this.getArticles();
  },
  methods: {
    getMoreArticles(page) {
      this.filters.page = page - 1;
      this.currentPage = page;
      this.getArticles();
    },
    async getArticles() {
      this.loading = true;
      try {
        let res = await TheHubService.listArticles(this.filters);
        this.articles = res.data.data;

        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get articles, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 15,
        search: "",
        order_by: "date",
        order: "desc",
        published: "",
        author_uid: "",
      };
      this.selectedSort = "newest";
      this.selectedAuthor = null;

      this.getArticles();
    },
    closeModals() {
      this.$bvModal.hide("modal-create-article");
      this.$bvModal.hide("modal-create-article-from-pdf");
    },
    updateOrderFilter(selected) {
      switch (selected) {
        case "newest":
          this.filters.order_by = "desc";
          this.filters.order = "date";
          break;
        case "oldest":
          this.filters.order_by = "asc";
          this.filters.order = "date";
          break;
        case "likes":
          this.filters.order_by = "desc";
          this.filters.order = "like_count";
          break;
        case "comments":
          this.filters.order_by = "desc";
          this.filters.order = "comment_count";
          break;
      }
      this.getMoreArticles(1);
    },
    updateSelectedAuthor(author) {
      this.selectedAuthor = author;
      if (!this.selectedAuthor) {
        this.filters.author_uid = "";
      } else {
        this.filters.author_uid = author.uid;
      }

      this.getMoreArticles(1);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/themes/dark-layout.scss";

.dark-layout {
  #modal-create-article {
    .modal-body {
      background-color: $theme-dark-body-bg !important;
    }
  }
}

#modal-create-article {
  .modal-body {
    background-color: $body-bg !important;
  }
}
</style>
