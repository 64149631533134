<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <div class="row flex-lg-row-reverse">
        <div class="col-lg-4">
          <div class="row">
            <!-- General Actions -->
            <div class="col-sm-6 col-lg-12 d-flex">
              <b-card class="flex-grow-1" title="Actions">
                <div v-if="!$props.article">
                  <b-btn
                    v-if="$can('Create', 'TheHub.Articles')"
                    :disabled="loading"
                    class="full-width mb-1"
                    variant="success"
                    @click="handleSubmit(createArticle)"
                  >
                    Create
                  </b-btn>
                </div>
                <div v-else>
                  <b-btn
                    :disabled="loading"
                    class="full-width mb-1"
                    type="submit"
                    variant="primary"
                    @click="handleSubmit(saveChanges)"
                  >
                    Save Changes
                  </b-btn>
                  <b-btn
                    class="full-width mb-1"
                    variant="success"
                    @click="showModal('article-preview-modal')"
                  >
                    Preview
                  </b-btn>
                  <b-btn
                    v-if="$can('Delete', 'TheHub.Articles')"
                    :disabled="loading"
                    class="full-width mb-1"
                    type="submit"
                    variant="danger"
                    @click="showDeleteArticleModal"
                  >
                    Delete Article
                  </b-btn>
                </div>
              </b-card>
            </div>

            <!-- PDF -->
            <div class="col-sm-6 col-lg-12 d-flex">
              <b-card class="flex-grow-1" title="PDF">
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ isPDFFile }"
                  name="File"
                >
                  <label>PDF</label>
                  <b-form-file
                    v-model="pdf"
                    :state="getValidationState(validationContext)"
                    accept=".pdf"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose PDF or drag one here..."
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <small>
                  This will replace the existing PDF. This action can not be
                  undone.
                </small>

                <div v-if="pdfUrl" class="mt-1 d-flex flex-wrap">
                  <b-button
                    class="mr-1 mt-1 d-flex align-items-center"
                    variant="primary"
                    @click="$bvModal.show('article-pdf-preview-modal')"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="16"
                      style="margin-right: 4px"
                    />
                    <span>View</span>
                  </b-button>

                  <b-button
                    v-if="$props.article"
                    class="mr-1 mt-1"
                    variant="success"
                    @click="$bvModal.show('confirm-generating-summary-modal')"
                  >
                    <span>Summarise</span>
                  </b-button>

                  <b-button
                    v-if="$can('Update', 'TheHub.Articles')"
                    class="mt-1 mr-1"
                    variant="danger"
                    @click="showDeletePDFModal"
                  >
                    <span>Delete</span>
                  </b-button>
                </div>
              </b-card>
            </div>

            <!-- Video -->
            <div class="col-sm-6 col-lg-12 d-flex">
              <b-card class="flex-grow-1" title="Video">
                <validation-provider
                  v-slot="validationContext"
                  :rules="{ isVideoFile }"
                  name="File"
                >
                  <label>Video</label>
                  <b-form-file
                    v-model="video"
                    :state="getValidationState(validationContext)"
                    accept=".mp4"
                    drop-placeholder="Drop file here..."
                    placeholder="Choose a video or drag one here..."
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>

                <small>
                  This will replace the existing video. This action can not be
                  undone.
                </small>

                <div v-if="videoURL" class="mt-1 d-flex">
                  <b-button
                    class="mr-1 d-flex align-items-center"
                    variant="primary"
                    @click="showModal('article-video-preview-modal')"
                  >
                    <feather-icon
                      icon="PlayCircleIcon"
                      size="16"
                      style="margin-right: 4px"
                    />
                    <span>Play</span>
                  </b-button>

                  <b-button
                    v-if="$can('Update', 'TheHub.Articles')"
                    variant="danger"
                    @click="showDeleteVideoModal"
                  >
                    <span>Delete</span>
                  </b-button>
                </div>
              </b-card>
            </div>

            <!-- Tags -->
            <div
              v-if="$can('Read', 'TheHub.Tags')"
              class="col-sm-6 col-lg-12 d-flex"
            >
              <b-card class="flex-grow-1" title="Tags">
                <article-tags-editor
                  :article-tags="articleData.tags"
                  @update="updateArticleTags"
                />
              </b-card>
            </div>

            <!-- Author -->
            <div v-if="$can('Read', 'User')" class="col-sm-6 col-lg-12 d-flex">
              <b-card class="flex-grow-1" title="Author">
                <article-author-editor
                  :article-author="articleData.author"
                  @update:author="(v) => (articleData.author = v)"
                />
              </b-card>
            </div>

            <!-- Settings -->
            <div class="col-sm-6 col-lg-12 d-flex">
              <b-card class="flex-grow-1" title="Settings">
                <article-settings-editor
                  :article-allow-comments="articleData.allow_comments"
                  :article-allow-likes="articleData.allow_likes"
                  :article-is-published="articleData.is_published"
                  @update:allowLikes="(v) => (articleData.allow_likes = v)"
                  @update:allowComments="
                    (v) => (articleData.allow_comments = v)
                  "
                  @update:isPublished="(v) => (articleData.is_published = v)"
                />
              </b-card>
            </div>
          </div>
        </div>

        <div class="col-lg-8">
          <b-form @submit.stop.prevent="handleSubmit">
            <b-card title="Details">
              <div class="row">
                <div class="col-md-8 mb-2">
                  <section>
                    <validation-provider
                      v-slot="validationContext"
                      :rules="{ required: true, max: 1000 }"
                      name="Title"
                    >
                      <label>Title</label>
                      <b-form-input
                        v-model="articleData.title"
                        :max="1000"
                        :state="getValidationState(validationContext)"
                        class="mb-1"
                        placeholder="Enter article title"
                      />

                      <small class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </validation-provider>
                  </section>

                  <section>
                    <validation-provider
                      v-slot="validationContext"
                      :rules="{ required: true }"
                      name="Published On"
                    >
                      <label>Published On</label>
                      <b-form-datepicker
                        v-model="articleData.date"
                        :state="getValidationState(validationContext)"
                        placeholder="Enter article date"
                      />
                      <small class="mb-1">
                        If the selected date is in the future, this article will
                        be made public automatically on that date.
                      </small>
                      <small class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </validation-provider>
                  </section>

                  <section>
                    <validation-provider
                      v-slot="validationContext"
                      :rules="{ required: true }"
                      name="Excerpt"
                    >
                      <label>Excerpt</label>
                      <b-form-textarea
                        v-model="articleData.excerpt"
                        :state="getValidationState(validationContext)"
                        class="mb-1"
                        placeholder="Enter article excerpt"
                      />

                      <small class="text-danger">
                        {{ validationContext.errors[0] }}
                      </small>
                    </validation-provider>
                  </section>

                  <section>
                    <div class="d-flex align-baseline">
                      <validation-provider
                        v-slot="validationContext"
                        :rules="{ isImageFile }"
                        class="flex-grow-1"
                        name="File"
                      >
                        <label>Thumbnail</label>
                        <b-form-file
                          v-model="thumbnail"
                          :state="getValidationState(validationContext)"
                          accept=".jpg, .jpeg, .png, .webp"
                          drop-placeholder="Drop file here..."
                          placeholder="Choose an image or drag one here..."
                          @input="selectThumbnail"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>

                      <div v-if="thumbnailURL" class="ml-1 d-flex flex-column">
                        <div class="flex-grow-1" />
                        <b-button
                          v-if="$can('Update', 'TheHub.Articles')"
                          variant="danger"
                          @click="showDeleteThumbnailModal"
                        >
                          <span>Delete</span>
                        </b-button>
                      </div>
                    </div>
                    <small>
                      This will replace the existing thumbnail. This action can
                      not be undone.
                    </small>
                  </section>
                </div>

                <div class="col-md-4 my-auto">
                  <div class="article-thumbnail">
                    <img
                      v-if="thumbnailURL"
                      :src="thumbnailURL"
                      alt=""
                      class="full-width full-height"
                    >
                    <div v-else class="d-flex flex-column align-items-center">
                      <img alt="" src="@/assets/images/icons/jpg.png">
                      <div class="mt-2">No Thumbnail Selected</div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>

            <b-card title="Body">
              <!-- WYSIWYG Editor -->
              <ck-editor
                :model-value="articleData.body"
                @update:modelValue="updateArticleBody"
              />
            </b-card>
          </b-form>
        </div>
      </div>
    </validation-observer>

    <b-modal
      id="article-preview-modal"
      hide-footer
      size="xl"
      title="Article Preview"
    >
      <article-preview
        :article="articleData"
        :pdf="pdfUrl"
        :thumbnail="thumbnailURL"
        :video="videoURL"
      />
    </b-modal>

    <b-modal
      id="article-video-preview-modal"
      hide-footer
      size="lg"
      title="Video Preview"
    >
      <video :src="videoURL" class="full-width full-height" controls />
    </b-modal>

    <b-modal
      v-if="thumbnail"
      id="thumbnail-cropper-modal"
      hide-footer
      size="lg"
      title="Crop Thumbnail"
    >
      <thumbnail-cropper-modal
        :chosen-image="thumbnailBase64"
        :file-type="thumbnail.type"
        @close="closeThumbnailCropperModal"
        @crop="cropThumbnail"
      />
    </b-modal>

    <b-modal
      id="article-pdf-preview-modal"
      hide-footer
      size="lg"
      title="PDF Preview"
    >
      <PDFView
        v-if="pdfUrl"
        :download-feature-visible="false"
        :dropzone-feature-visible="false"
        :sidebar-feature-visible="false"
        :src.sync="pdfUrl"
      />
    </b-modal>

    <b-modal
      id="confirm-generating-summary-modal"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      size="md"
      title="Generating Summary"
    >
      <loading-carousel v-if="loading" :states="carouselStates">
        <template #message>
          Purple AI is generating your article. This may take a while, please
          don't leave this page.
        </template>
      </loading-carousel>

      <div v-else>
        <span>
          Are you sure you want to generate a summary for this PDF? This will
          replace the existing title, excerpt and body for this article.
        </span>
        <div class="d-flex flex-row-reverse mt-2">
          <b-button variant="success" @click="generatePDFSummary">
            Accept
          </b-button>
          <b-button
            class="mr-1"
            variant="danger"
            @click="$bvModal.hide('confirm-generating-summary-modal')"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import auth from "@/auth/auth";
import TheHubService from "@/services/TheHubService";
import CkEditor from "@/components/the-hub/WYSIWYG/CkEditor.vue";
import ArticlePreview from "@/views/theHub/sections/ArticlePreview.vue";
import ArticleTagsEditor from "@/views/theHub/sections/ArticleTagsEditor.vue";
import {
  isImageFile,
  isPDFFile,
  isVideoFile,
} from "@/@core/utils/validations/validations";
import ArticleAuthorEditor from "@/views/theHub/sections/ArticleAuthorEditor.vue";
import ArticleSettingsEditor from "@/views/theHub/sections/ArticleSettingsEditor.vue";
import {
  validatorIsImageFile,
  validatorIsPDFFile,
  validatorIsVideoFile,
} from "@/@core/utils/validations/validators";
import CountryService from "@/services/CountryService";
import ThumbnailCropperModal from "@/views/theHub/sections/ThumbnailCropperModal.vue";
import { PDFView } from "@gabrielbu/vue-pdf-viewer";
import LoadingCarousel from "@/components/loading/LoadingCarousel.vue";

export default {
  name: "ArticleEditor",
  components: {
    LoadingCarousel,
    ThumbnailCropperModal,
    ArticlePreview,
    ArticleAuthorEditor,
    ArticleSettingsEditor,
    ArticleTagsEditor,
    CkEditor,
    PDFView,
  },
  props: {
    article: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      articleData: {
        title: "",
        body: "",
        excerpt: "",
        allow_comments: false,
        allow_likes: false,
        is_published: false,
        date: new Date(),
        author: auth.getCurrentUser(),
        tags: [],
      },
      allTags: [],
      loading: false,
      thumbnail: null,
      thumbnailBase64: "",
      thumbnailURL: "",
      thumbnailChanged: false,
      video: null,
      videoURL: "",
      videoChanged: false,
      pdf: null,
      pdfUrl: "",
      pdfChanged: false,
      carouselStates: [
        {
          image: require("@/assets/images/the-hub/uploading-pdf.png"),
          message: "Uploading your PDF...",
        },
        {
          image: require("@/assets/images/the-hub/analysing-pdf.png"),
          message: "Analysing your PDF...",
        },
        {
          image: require("@/assets/images/the-hub/cleaning-up.png"),
          message: "Cleaning up your article...",
        },
      ],
    };
  },
  computed: {
    isImageFile() {
      return isImageFile;
    },
    isVideoFile() {
      return isVideoFile;
    },
    isPDFFile() {
      return isPDFFile;
    },
  },
  watch: {
    async video() {
      if (validatorIsVideoFile(this.video)) {
        this.videoURL = URL.createObjectURL(this.video);
        this.videoChanged = true;
      }
    },
    async pdf() {
      if (validatorIsPDFFile(this.pdf)) {
        this.pdfUrl = URL.createObjectURL(this.pdf);
        this.pdfChanged = true;
      }
    },
  },
  async beforeMount() {
    if (this.$props.article) {
      this.articleData.title = this.$props.article.title;
      this.articleData.body = this.$props.article.body;
      this.articleData.excerpt = this.$props.article.excerpt;
      this.articleData.allow_comments = this.$props.article.allow_comments;
      this.articleData.allow_likes = this.$props.article.allow_likes;
      this.articleData.is_published = this.$props.article.is_published;
      this.articleData.date = this.$props.article.date;
      this.articleData.author = this.$props.article.author;
      this.articleData.tags = this.$props.article.tags;

      if (this.$props.article.thumbnail) {
        this.thumbnailURL = `${CountryService.getApiUrl()}/files/${
          this.article.thumbnail.file_location
        }/${this.article.thumbnail.filename}`;
      }
      if (this.$props.article.video) {
        this.videoURL = `${CountryService.getApiUrl()}/files/${
          this.article.video.file_location
        }/${this.article.video.filename}`;
      }
      if (this.$props.article.pdf) {
        // We have to do this as I couldn't find a way to make the PDF viewer use our files cookie
        // Pressed for time, so left for now, but would be good to replace with a better package
        const res = await fetch(
          `${CountryService.getApiUrl()}/files/${
            this.article.pdf.file_location
          }/${this.article.pdf.filename}`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        const blob = await res.blob();
        this.pdfUrl = URL.createObjectURL(blob);
      }
    }
  },
  methods: {
    updateArticleBody(value) {
      this.articleData.body = value;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async createArticle() {
      if (this.loading) return;

      this.loading = true;
      try {
        let articleDTO = {
          tag_ids: this.articleData.tags.map(tag => tag.id),
          title: this.removeSpecialCharacters(this.articleData.title),
          body: this.removeSpecialCharacters(this.articleData.body),
          excerpt: this.removeSpecialCharacters(this.articleData.excerpt),
          allow_likes: this.articleData.allow_likes,
          allow_comments: this.articleData.allow_comments,
          is_published: this.articleData.is_published,
          author_uid: this.articleData.author?.uid,
          date: new Date(this.articleData.date).toISOString(),
        };

        let res = await TheHubService.createArticle(articleDTO);
        if (this.thumbnailChanged) {
          await TheHubService.uploadArticleThumbnail(
            res.data.id,
            this.thumbnail
          );
        }
        if (this.videoChanged) {
          await TheHubService.uploadArticleVideo(res.data.id, this.video);
        }
        if (this.pdfChanged) {
          await TheHubService.uploadArticlePDF(res.data.id, this.pdf);
        }

        this.$router.push(`/the-hub/articles/${res.data.id}`);
        this.$toast.success("Article was successfully created.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not create article, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.thumbnailChanged = false;
        this.videoChanged = false;
        this.pdfChanged = false;

        this.loading = false;
      }
    },
    async saveChanges() {
      if (this.loading) return;

      this.loading = true;
      try {
        let articleDTO = {
          tag_ids: this.articleData.tags.map(tag => tag.id),
          title: this.removeSpecialCharacters(this.articleData.title),
          body: this.removeSpecialCharacters(this.articleData.body),
          excerpt: this.removeSpecialCharacters(this.articleData.excerpt),
          allow_likes: this.articleData.allow_likes,
          allow_comments: this.articleData.allow_comments,
          is_published: this.articleData.is_published,
          author_uid: this.articleData.author.uid,
          date: new Date(this.articleData.date).toISOString(),
        };
        await TheHubService.updateArticle(this.$route.params.id, articleDTO);
        if (this.thumbnailChanged) {
          await TheHubService.uploadArticleThumbnail(
            this.$route.params.id,
            this.thumbnail
          );
        }
        if (this.videoChanged) {
          await TheHubService.uploadArticleVideo(
            this.$route.params.id,
            this.video
          );
        }
        if (this.pdfChanged) {
          await TheHubService.uploadArticlePDF(this.$route.params.id, this.pdf);
        }

        this.$toast.success("Article was successfully updated.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not update article, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.thumbnailChanged = false;
        this.videoChanged = false;
        this.pdfChanged = false;

        this.loading = false;
      }
    },
    async deleteArticle() {
      if (this.loading) return;

      this.loading = true;
      try {
        await TheHubService.deleteArticle(this.$route.params.id);
        this.$router.push({ name: "articles" });
        this.$toast.success("Article was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not delete article, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteThumbnail() {
      if (this.loading) return;

      this.loading = true;
      try {
        this.thumbnailURL = "";
        this.thumbnailChanged = false;
        this.thumbnail = null;

        if (this.article && this.article.thumbnail) {
          await TheHubService.deleteArticleThumbnail(this.$route.params.id);
        }

        this.$toast.success("Thumbnail was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not delete thumbnail, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async deleteVideo() {
      if (this.loading) return;

      this.loading = true;
      try {
        this.videoURL = "";
        this.videoChanged = false;
        this.video = null;

        if (this.article && this.article.video) {
          await TheHubService.deleteArticleVideo(this.$route.params.id);
        }

        this.$toast.success("Video was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not delete video, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async deletePDF() {
      if (this.loading) return;

      this.loading = true;
      try {
        this.pdfUrl = "";
        this.pdfChanged = false;
        this.pdf = null;

        if (this.article && this.article.pdf) {
          await TheHubService.deleteArticlePDF(this.$route.params.id);
        }

        this.$toast.success("PDF was successfully deleted.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText = "Could not delete pdf, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    async generatePDFSummary() {
      if (this.loading) return;

      this.loading = true;
      try {
        // Upload the latest PDF
        if (this.pdfChanged) {
          await TheHubService.uploadArticlePDF(this.$route.params.id, this.pdf);
          this.pdfChanged = false;
        }

        const res = await TheHubService.generateArticlePDFSummary(
          this.$route.params.id
        );
        this.articleData.title = res.data.title;
        this.articleData.excerpt = res.data.excerpt;
        this.articleData.body = res.data.body;

        this.$toast.success("Article was successfully updated.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        const res = err.response;
        let errorText =
          "Could not generate PDF summary, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
        this.$bvModal.hide("confirm-generating-summary-modal");
      }
    },
    updateArticleTags(newTags) {
      this.articleData.tags = newTags;
    },
    showDeleteArticleModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this article? This action cannot be undone.",
          {
            title: "Delete Article",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteArticle();
          }
        });
    },
    showDeleteThumbnailModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this thumbnail? This action cannot be undone.",
          {
            title: "Delete Thumbnail",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteThumbnail();
          }
        });
    },
    showDeleteVideoModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this video? This action cannot be undone.",
          {
            title: "Delete Video",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deleteVideo();
          }
        });
    },
    showDeletePDFModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this PDF? This action cannot be undone.",
          {
            title: "Delete PDF",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(value => {
          if (value) {
            this.deletePDF();
          }
        });
    },
    removeSpecialCharacters(input) {
      // Strips the special characters from a string; \n, \t, \n\t, &nbsp;, \u00A0 and u00A0.
      // These characters can cause text to overflow.
      return input
        .replace(/\\n\\t/g, " ")
        .replace(/\\f/g, " ")
        .replace(/\\n/g, " ")
        .replace(/\\t/g, " ")
        .replace(/&nbsp;/g, " ")
        .replace(/\\u00A0/g, " ")
        .replace(/\u00A0/g, " ")
        .trim();
    },
    showModal(id) {
      this.$bvModal.show(id);
    },
    async selectThumbnail() {
      if (validatorIsImageFile(this.thumbnail)) {
        this.thumbnailBase64 = await this.toBase64(this.thumbnail);
        this.$bvModal.show("thumbnail-cropper-modal");
      }
    },
    async toBase64(file) {
      if (!file) return null;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    cropThumbnail(croppedURL, blob) {
      this.thumbnail = new File(
        [blob],
        "thumbnail." + blob.type.split("/")[1],
        { type: blob.type }
      );
      this.thumbnailURL = croppedURL;
      this.thumbnailChanged = true;
      this.$bvModal.hide("thumbnail-cropper-modal");
    },
    closeThumbnailCropperModal() {
      this.$bvModal.hide("thumbnail-cropper-modal");
      this.thumbnail = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-thumbnail {
  aspect-ratio: 1/1;
  max-height: 300px;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 16px;
  overflow: hidden;
}
</style>
