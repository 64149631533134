<template>
  <div>
    <loading-carousel v-if="loading" :states="carouselStates">
      <template #message>
        Purple AI is generating your article. This may take a while, please
        don't leave this page.
      </template>
    </loading-carousel>
    <div v-else>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(createArticle)">
          <!-- File Upload -->
          <label>PDF</label>
          <validation-provider
            v-slot="validationContext"
            :rules="{
              required: true,
              isPDFFile,
            }"
            name="File"
          >
            <b-form-file
              v-model="pdf"
              :state="getValidationState(validationContext)"
              accept=".pdf"
              drop-placeholder="Drop file here..."
              placeholder="Choose a PDF file or drag it here..."
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>

          <section class="d-flex flex-row-reverse mt-3">
            <b-btn
              :disabled="loading || pdfUrl === ''"
              type="submit"
              variant="primary"
            >
              Generate Article
            </b-btn>
            <b-button
              v-if="pdfUrl"
              :disabled="loading"
              class="d-flex align-items-center mr-1"
              @click="$bvModal.show('article-pdf-preview-modal')"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
                style="margin-right: 4px"
              />
              <span>View</span>
            </b-button>
            <b-btn
              class="d-inline mr-1"
              variant="danger"
              @click="$emit('close')"
            >
              Cancel
            </b-btn>
          </section>
        </b-form>
      </validation-observer>

      <b-modal
        id="article-pdf-preview-modal"
        hide-footer
        size="lg"
        title="PDF Preview"
      >
        <PDFView
          v-if="pdfUrl"
          :download-feature-visible="false"
          :dropzone-feature-visible="false"
          :sidebar-feature-visible="false"
          :src.sync="pdfUrl"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import auth from "@/auth/auth";
import { PDFView } from "@gabrielbu/vue-pdf-viewer";
import TheHubService from "@/services/TheHubService";
import { isPDFFile } from "@/@core/utils/validations/validations";
import LoadingCarousel from "@/components/loading/LoadingCarousel.vue";
import { validatorIsPDFFile } from "@core/utils/validations/validators";

export default {
  name: "ArticleFromPdf",
  components: {
    LoadingCarousel,
    PDFView,
  },
  data() {
    return {
      loading: false,
      pdf: null,
      pdfUrl: "",
      articleID: 0,
      carouselStates: [
        {
          image: require("@/assets/images/the-hub/uploading-pdf.png"),
          message: "Uploading your PDF...",
        },
        {
          image: require("@/assets/images/the-hub/analysing-pdf.png"),
          message: "Analysing your PDF...",
        },
        {
          image: require("@/assets/images/the-hub/cleaning-up.png"),
          message: "Cleaning up your article...",
        },
      ],
    };
  },
  computed: {
    isPDFFile() {
      return isPDFFile;
    },
  },
  watch: {
    async pdf() {
      if (validatorIsPDFFile(this.pdf)) {
        this.pdfUrl = URL.createObjectURL(this.pdf);
        this.pdfChanged = true;
      } else {
        this.pdfUrl = "";
        this.pdfChanged = false;
      }
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async createArticle() {
      if (this.loading) return;

      this.loading = true;
      try {
        let date = new Date();
        let createArticleDTO = {
          title: "Placeholder Title",
          excerpt: "Placeholder Excerpt",
          body: "Placeholder Body",
          tag_ids: [],
          author_uid: auth.getCurrentUser().uid,
          date: date.toISOString(),
        };
        const res = await TheHubService.createArticle(createArticleDTO);
        await TheHubService.uploadArticlePDF(res.data.id, this.pdf);

        this.articleID = res.data.id;

        // Generate Summary from PDF
        const summaryRes = await TheHubService.generateArticlePDFSummary(
          res.data.id
        );

        // Update article details
        let updateArticleDTO = {
          title: summaryRes.data.title,
          excerpt: summaryRes.data.excerpt,
          body: summaryRes.data.body,
          author_uid: auth.getCurrentUser().uid,
          date: date.toISOString(),
        };
        await TheHubService.updateArticle(res.data.id, updateArticleDTO);

        this.$router.push(`/the-hub/articles/${res.data.id}`);
        this.$toast.success("Article was successfully created.", {
          toastClassName: ["toast-std", "success-toast"],
        });
      } catch (err) {
        // Check to see if article needs deleting
        if (this.articleID !== 0) {
          await TheHubService.deleteArticle(this.articleID);
        }

        const res = err.response;
        let errorText =
          "Could not create article, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
