<template>
  <paginated-search-select
    :selected="selectedUser"
    :get-label="getLabel"
    :options="users"
    :template="template"
    placeholder="Search users..."
    @change="updateSelectedUser"
    @change-search="updateUserFilter"
  />
</template>

<script>
import UserService from "@/services/UserService";
import UserTemplate from "@/components/input/CAutoCompleteTemplates/UserTemplate.vue";
import PaginatedSearchSelect from "@/components/input/PaginatedSearchSelect/PaginatedSearchSelect.vue";

export default {
  name: "UserSearchSelect",
  components: { PaginatedSearchSelect },
  props: {
    selected: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: true,
      selectedUser: this.$props.selected,
      users: [],
      userFilters: {
        page: 0,
        page_size: 15,
        search: "",
      },
      template: UserTemplate,
    }
  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          this.$emit('loading');
        } else {
          this.$emit('loaded');
        }
      }
    },
    selected: {
      deep: true,
      handler() {
        this.selectedUser = this.$props.selected;
      }
    },
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getMoreUsers(page) {
      this.userFilters.page = page - 1;
      this.getUsers();
    },
    async getUsers() {
      this.loading = true;
      try {
        const res = await UserService.getUsers(this.userFilters);
        this.users = res.data.data;
      } catch (err) {
        const res = err.response;
        let errorText = "Could not get users, please refresh and try again";

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ["toast-std", "warning-toast"],
        });
      } finally {
        this.loading = false;
      }
    },
    getLabel(user) {
      if (!user) return "";
      return user.name;
    },
    updateSelectedUser(user) {
      this.$emit("change", user);
    },
    updateUserFilter(search) {
      this.userFilters.search = search;
      this.getMoreUsers(1);
    },
  },
};
</script>

<style scoped lang="scss"></style>
